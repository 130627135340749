import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '@/app/models/user';
import { RateAndComment, RatingsAndComments } from '@/app/models/material';
import { UserObject } from '@/app/pages/user-def-dialog/user-def-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    basicUrl: string = environment.backendApi + '/v1/';
    user: any;
    private userData = new BehaviorSubject<any>(null);
    public userData$ = this.userData.asObservable();
    constructor(private http: HttpClient) {}

    setUserData(data: UserObject): void {
        this.userData.next(data);
    }

    getUser(userEmail: string): Observable<any> {
        const param = new HttpParams().set('userEmail', userEmail);
        return this.http.get<any>(this.basicUrl + 'user/exists', { params: param });
    }

    saveUser(userDetails: any): Observable<any> {
        return this.http.post<User[]>(this.basicUrl + 'user/save-details', userDetails);
    }

    saveMaterialComment(rateAndCommentDTO: RateAndComment): Observable<any> {
        return this.http.post(this.basicUrl + 'user-rating/save', rateAndCommentDTO, {
            responseType: 'text',
        });
    }

    getCommentsByMaterialNumber(matnr: string, affiliate: string): Observable<RatingsAndComments[]> {
        let params = new HttpParams();
        if (matnr) {
            params = params.append('matnr', matnr);
        }
        if (affiliate) {
            params = params.append('affiliate', affiliate);
        }
        return this.http.get<RatingsAndComments[]>(this.basicUrl + 'user/rating-comment', {
            params,
        });
    }
}
