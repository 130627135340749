<div *ngIf="viewPanel" class="card-body panel-container">
    <div class="container-modal">
        <span (click)="closePanel()" class="material-icons close-icon" aria-hidden="true">clear</span>
        <div class="card-description">
            <span class="material-icons menu-icon">visibility</span
            ><span
                >Long Description Material N°{{ matnr }} -
                <ng-container class="id-data-long-desc" *ngFor="let sd of dataLongDescription?.shortDescription">{{ sd }}</ng-container>
            </span>
        </div>

        <p class="id-data-long-desc" *ngFor="let ld of dataLongDescription?.longDescription">{{ ld }}</p>
    </div>
</div>
