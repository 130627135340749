<div>
    <app-header></app-header>
    <div class="page-body-wrapper d-flex">
        <div class="sidebar-wrapper" [class.close_icon]="sidebarService.collapseSidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="content-wrapper">
            <main [@fadeInAnimation]="getRouterOutletState(outlet)">
                <router-outlet #outlet="outlet"></router-outlet>
            </main>
        </div>
    </div>
    <app-footer></app-footer>
</div>
