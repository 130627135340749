import { Component, OnDestroy, OnInit } from '@angular/core';

import { Language } from '@types';
import { AuthService, TranslationService } from '@/app/shared/services';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { SIDEBAR_STORAGE_KEY } from '@consts';
import { getDataFromLocalStorage, setDataFromLocalStorage } from '@/app/shared/utils';
import { UserService } from '../../services/user.service';
import { UserDefDialogComponent, UserObject } from '@/app/pages/user-def-dialog/user-def-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    public userName: string;
    public userEmail: string;
    public currentLanguage = Language.EN;
    public Language = Language;
    isSidebarToggled = true;
    userData: any;
    currentUser: any;

    constructor(
        private authService: AuthService,
        private translationService: TranslationService,
        private sidebarService: SidebarService,
        private userService: UserService,
        private dialog: MatDialog
    ) {
        this.translationService.currentLanguage.subscribe((currentLanguage: Language) => {
            this.currentLanguage = currentLanguage;
        });
    }

    ngOnInit(): void {
        this.authService.currentUser.subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.userName = currentUser?.name ?? '';
            this.userEmail = currentUser?.username ?? '';
        });
    }

    logout(): void {
        this.authService.logout();
    }

    changeLanguage(language: Language): void {
        this.translationService.changeLanguage(language);
    }

    openUserDefDialog(): void {
        const userDef = this.currentUser as UserObject;
        this.dialog
            .open(UserDefDialogComponent, {
                data: userDef,
                maxHeight: '80vh',
                width: '670px',
                autoFocus: false,
                hasBackdrop: true,
                disableClose: false,
            })
            .afterClosed()
            .subscribe((results: UserObject) => {
                const userData = results;
                this.userService.saveUser(results).subscribe((result) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    this.userData = result;
                    this.userService.setUserData(userData);
                });
            });
    }

    toggleSidebar(): void {
        this.isSidebarToggled = !getDataFromLocalStorage<boolean>(SIDEBAR_STORAGE_KEY, true);
        setDataFromLocalStorage(SIDEBAR_STORAGE_KEY, this.isSidebarToggled);
        this.sidebarService.onSidebarToggled(this.isSidebarToggled);
    }

    ngOnDestroy(): void {
        this.translationService.currentLanguage.unsubscribe();
    }
}
