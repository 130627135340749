<div class="dialog-title mb-5">Your Profile</div>
<div class="row">
    <div class="row mb-4">
        <div class="col-4 label">Define Country :</div>
        <div class="col d-inline-flex">
            <div [matMenuTriggerFor]="menu" class="d-inline-flex select-menu">
                <span class="img-container"><img src="assets/images/logo/{{ selectedCountry }}.png" alt="selectedCountry" /></span>
                <span class="material-icons align-text-top md-18 me-1">expand_more</span>
            </div>
            <mat-menu #menu="matMenu" class="userMenu">
                <button
                    mat-menu-item
                    *ngFor="let countryIter of countries"
                    [matTooltip]="countryIter"
                    matTooltipPosition="left"
                    (click)="setCountry(countryIter)"
                >
                    <span class="img-container d-flex align-item-center"
                        ><img src="assets/images/logo/{{ countryIter }}.png" alt="{{ countryIter }}"
                    /></span>
                </button>
            </mat-menu>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-4 label">Department :</div>
        <div class="col">
            <ng-select
                [items]="departments"
                [(ngModel)]="department"
                (change)="setDepartment(department)"
                bindLabel="department"
                placeholder="departments"
            >
            </ng-select>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-4 label">Discipline :</div>
        <div class="col">
            <ng-select
                [items]="disciplines"
                [(ngModel)]="discipline"
                (change)="setDiscipline(discipline)"
                bindLabel="discipline"
                placeholder="disciplines"
            >
            </ng-select>
        </div>
    </div>
</div>

<div class="save-btn">
    <button type="button" mat-dialog-close="data" class="btn ms-2 btn-primary" [disabled]="!isFormValid()" (click)="save(data)">
        Save
    </button>
</div>
