import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
    AccountInfo,
    AuthenticationResult,
    EventMessage,
    EventType,
    EventError,
    InteractionStatus,
    SilentRequest,
    AuthError,
} from '@azure/msal-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ROUTES } from '@consts';
import { switchCase } from '@utils';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { UserDefDialogComponent, UserObject } from '@/app/pages/user-def-dialog/user-def-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    error: EventError = null;
    authenticated = new BehaviorSubject(false);
    authenticationPending = new BehaviorSubject(true);
    private readonly destroying$ = new Subject<void>();
    currentUser = new BehaviorSubject<AccountInfo | null>(null);
    appInsights: ApplicationInsights;
    userData: any;
    public userExist = false;

    constructor(
        private router: Router,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private dialog: MatDialog,
        private userService: UserService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
    ) {
        this.authenticationInit();
        this.msalBroadcastService.msalSubject$
            .pipe(takeUntil(this.destroying$))
            .subscribe((event: EventMessage) => this.onAuthenticationInProgress(event));

        if (this.msalService.instance.getActiveAccount()) {
            this.msalBroadcastService.inProgress$
                .pipe(
                    filter((status: InteractionStatus) => status === InteractionStatus.None),
                    takeUntil(this.destroying$)
                )
                .subscribe(() => {
                    this.onAuthenticationSucceeded();
                });
        }

        this.initAppInsight();
    }

    private initAppInsight(): void {
        const angularPlugin = new AngularPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.azure.azureInsightKey,
                extensions: [angularPlugin],
                enableAutoRouteTracking: true, // option to log all route changes
            },
        });
        this.appInsights.loadAppInsights();
    }

    private authenticationInit(): void {
        const activeAccount = this.msalService.instance.getActiveAccount();
        const allAccounts = this.msalService.instance.getAllAccounts();

        // If no active account is set but there are accounts signed in, activate 1st account.
        if (!activeAccount && allAccounts.length > 0) {
            const [account] = this.msalService.instance.getAllAccounts();
            this.currentUser.next(account);
            this.saveAccount(account);
        }
    }

    private onAuthenticationInProgress(event: EventMessage): void {
        if (!/Start|End/.test(event.eventType)) {
            switchCase<() => void>({
                [EventType.LOGIN_SUCCESS]: () => void this.actionLogin((event.payload as AuthenticationResult).account),
                [EventType.ACQUIRE_TOKEN_SUCCESS]: () => void this.actionLogin((event.payload as AuthenticationResult).account),
                [EventType.LOGOUT_SUCCESS]: () => this.actionLogout(),
                _default: () => void this.actionLoginFailure(event.error),
            })(event.eventType)();
        }
    }

    private async actionLogin(account: AccountInfo | null): Promise<void> {
        if (account !== null) {
            this.currentUser.next(account);
            this.msalService.instance.setActiveAccount(account);
            this.authenticated.next(!!this.msalService.instance.getActiveAccount());
            // Set authenticated auth Id in app insight
            this.appInsights?.setAuthenticatedUserContext(account.username, account.localAccountId);
            this.appInsights?.trackEvent({ name: account.username });
            sessionStorage.setItem('userEmail', account.username);
            // Set authenticated user Id in app insight
            this.appInsights?.addTelemetryInitializer((envelope) => {
                const tags = envelope.tags;
                if (tags) {
                    tags['ai.user.id'] = account.username;
                }
            });

            this.checkUserDetails(account);
        } else {
            await this.actionLoginFailure(new AuthError('500', 'Issue related to Azure AD'));
        }
    }
    checkUserDetails(account: AccountInfo): void {
        if (!this.userExist) {
            const user = this.msalService.instance.getAllAccounts()[0];
            this.userExist = !!user;

            this.userService.user = user;
            this.userService.getUser(account.username).subscribe((userDef: UserObject) => {
                if (!userDef.userAffiliate && !userDef.discipline && !userDef.department) {
                    this.openUserDefDialog(account);
                } else {
                    this.userData = userDef;
                    this.userService.saveUser(userDef);
                }
                sessionStorage.setItem('contry', userDef.userAffiliate);
            });
        }
    }
    private actionLogout(): void {
        this.currentUser.next(null);
        this.authenticated.next(false);
        this.appInsights.clearAuthenticatedUserContext();
    }

    private async actionLoginFailure(error: EventError): Promise<void> {
        this.error = error;
        this.currentUser.next(null);
        this.authenticated.next(false);
        await this.router.navigate([ROUTES.LOGIN_FAILED]);
    }

    private onAuthenticationSucceeded(): void {
        this.msalBroadcastService.inProgress$.subscribe({
            next: (status: InteractionStatus) => {
                this.authenticationPending.next(status === InteractionStatus.None);
            },
        });
    }

    private saveAccount(account: AccountInfo): void {
        this.msalService.instance.setActiveAccount(account);
        this.onAuthenticationSucceeded();
    }

    /**
     * User login with Azure AD redirection.
     *
     * See also MSALGuardConfigFactory in msal.factory.ts.
     *
     * - If there is an MSAL active account, ask for a fresh authentication token and use this account.
     * - Else, redirect to Azure login, and back to this app after successfully being logged in.
     */
    login(): void {
        if (this.msalGuardConfig.authRequest && this.msalService.instance.getActiveAccount()) {
            this.msalService
                .acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as SilentRequest)
                .subscribe((response: AuthenticationResult) => {
                    if (response.account !== null) {
                        this.saveAccount(response.account);
                    }
                });
        } else {
            this.msalService.loginRedirect({
                redirectUri: environment.aad.redirectUri,
                scopes: ['user.read'],
            });
        }
    }

    logout(): void {
        this.msalService.logoutRedirect();
    }

    async redirectAuthenticatedUserToSearchPage(): Promise<void> {
        await this.router.navigate([ROUTES.PAGE_WORK_ORDERS]);
    }

    onDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
    }

    openUserDefDialog(userDef: AccountInfo): void {
        this.dialog
            .open(UserDefDialogComponent, {
                data: userDef,
                maxHeight: '80vh',
                width: '670px',
                autoFocus: false,
                hasBackdrop: true,
                disableClose: false,
            })
            .afterClosed()
            .subscribe((results: UserObject) => {
                const userData = results;
                this.userService.saveUser(results).subscribe((result) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    this.userData = result;
                    this.userService.setUserData(userData);
                });
            });
    }
}
