import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getDataFromLocalStorage } from '@utils';
import { SIDEBAR_STORAGE_KEY } from '@consts';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    public collapseSidebar: boolean = window.innerWidth < 991;
    private isSidebarToggled = new BehaviorSubject<boolean>(getDataFromLocalStorage<boolean>(SIDEBAR_STORAGE_KEY, true));
    isSidebarToggled$ = this.isSidebarToggled.asObservable();

    onSidebarToggled(value: boolean): void {
        this.isSidebarToggled.next(value);
    }
}
