import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { LongDescription } from '@app/models/long-description';

@Injectable({
    providedIn: 'root',
})
export class LongDescriptionService {
    basicUrl: string = environment.backendApi + '/v1/';

    private dataLongDescription = new BehaviorSubject<LongDescription | null>(null);
    sharedDataLongDescription = this.dataLongDescription.asObservable();

    private viewPanel = new BehaviorSubject<boolean>(false);
    sharedViewPanel = this.viewPanel.asObservable();

    matnr = new BehaviorSubject<string>('');
    sharedMatnr = this.matnr.asObservable();

    constructor(private http: HttpClient) {}

    getLongDescriptionLine(materialId: number): Observable<LongDescription> {
        let params = new HttpParams();
        params = params.set('materialId', materialId);
        return this.http.get<LongDescription>(this.basicUrl + 'long-description', { params });
    }

    setDataLongDescription(array: LongDescription): void {
        this.dataLongDescription.next(array);
    }
    setViewPanel(isShowPanel: boolean): void {
        return this.viewPanel.next(isShowPanel);
    }
    setMatnr(matnr: string): void {
        return this.matnr.next(matnr);
    }
}
