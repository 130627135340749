import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService } from '@app/shared/services/sidebar.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    displaySidebar: boolean;
    sidebarStatusSubscription: Subscription;

    constructor(private sidebarService: SidebarService) {}

    ngOnInit(): void {
        this.sidebarStatusSubscription = this.sidebarService.isSidebarToggled$.subscribe(
            (isSidebarDisplayed) => (this.displaySidebar = isSidebarDisplayed)
        );
    }

    ngOnDestroy(): void {
        this.sidebarStatusSubscription.unsubscribe();
    }
}
