import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { IPublicClientApplication, LogLevel, PublicClientApplication, InteractionType } from '@azure/msal-browser';

import { environment } from '@environments/environment';
import { ROUTES } from '@consts';

export const MSALGuardConfigFactory = (): MsalGuardConfiguration => ({
    interactionType: InteractionType.Redirect,
    authRequest: {
        scopes: ['user.read', environment.aad.scope],
    },
    loginFailedRoute: ROUTES.LOGIN_FAILED,
});

export const MSALInstanceFactory = (): IPublicClientApplication => {
    const {
        aad: { clientId, redirectUri, tenantId },
    } = environment;
    return new PublicClientApplication({
        auth: {
            clientId,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            redirectUri,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel[environment.debug.msal ? 'Trace' : 'Info'],
                piiLoggingEnabled: false,
            },
        },
    });
};

export const MSALInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/', ['user.read']);
    protectedResourceMap.set('/api/', [environment.aad.scope]);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
};
