export type Environment = {
    version?: string;
    production: boolean;
    debug: {
        msal: boolean;
        router: boolean;
    };
    azure: {
        azureInsightKey: string;
    };
    aad: {
        scope: string;
        clientId: string;
        tenantId: string;
        redirectUri: string;
    };
    backendApi: string;
    scefAppUrl: string;
    scefWorkOrderUrl: string;
    hotjar?: number;
    quantumUrl: string;
};
