import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

export interface FileHandle {
    file: File;
    url: SafeUrl;
}
@Directive({
    selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    @Output() files: EventEmitter<File[]> = new EventEmitter();
    @HostBinding('style.background-color') private background = '#F2FCFF';
    @HostBinding('style.border') private borderStyle = '2px dashed';
    @HostBinding('style.border-color') private borderColor = '#B8DBE6';
    @HostBinding('style.border-radius') private borderRadius = '5px';
    @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = 'lightgray';
        this.borderColor = 'cadetblue';
        this.borderStyle = '2px solid';
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#eee';
        this.borderColor = '#696D7D';
        this.borderStyle = '2px dashed';
    }

    @HostListener('drop', ['$event']) public ondrop(event: DragEvent): void {
        event?.preventDefault();
        event?.stopPropagation();
        this.background = 'transparent';
        const files = event.dataTransfer?.files;
        this.files?.emit(files as unknown as File[]);
    }
}
