export interface ConfigWorkOrder {
    resultDisplayOrder: string[];
}

const WORK_ORDERS_DISPLAY_ORDER = [
    'number',
    'country',
    'materialNumber',
    'deliveryStatus',
    'discipline',
    'materialType',
    'priceAndDate',
    'eta',
    'recommendation',
];

export const configWorkOrder: ConfigWorkOrder[] = [
    {
        resultDisplayOrder: WORK_ORDERS_DISPLAY_ORDER,
    },
];
export const Countries = ['Angola', 'Argentina', 'Congo', 'Denmark', 'Gabon', 'Nigeria', 'UK'];
export const Disciplines = ['Electrical', 'Instruments', 'HSE', 'Mechanical'];
export const Departments = ['Logistic', 'Maintenance', 'Procurement', 'Project', 'Stock'];
