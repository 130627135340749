// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from '@types';

export const environment: Environment = {
    production: false,
    debug: {
        msal: true,
        router: true,
    },
    azure: {
        azureInsightKey: '#{APPINSIGHTS_KEY}#',
    },
    aad: {
        scope: 'api://f394d901-c3f0-45f3-8f0f-2901eadb1286/all',
        clientId: '2b5693c0-a22e-4720-aab4-8d6513d4e2a6',
        tenantId: '329e91b0-e21f-48fb-a071-456717ecc28e',
        redirectUri: '/',
    },
    // backendApi: '#{BACKEND_URI}#/api',
    // backendApi: 'http://localhost:8080/api',
    backendApi: 'https://azfd-d-ims.azurefd.net/api',
    scefAppUrl: 'https://azrpscefappgwst.scef.iasp.tgscloud.net/details-for-material/',
    scefWorkOrderUrl: 'https://azrpscefappgwst.scef.iasp.tgscloud.net/details-for-wo/',
    hotjar: 3689515,
    quantumUrl: 'https://navigator.phusionim.com/',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
