import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Language } from '@types';
import { TRANSLATION_STORAGE_KEY } from '@consts';
import { getDataFromLocalStorage, setDataFromLocalStorage } from '@utils';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslationService {
    currentLanguage = new BehaviorSubject<Language>(Language.EN);

    constructor(private translate: TranslateService) {}

    public init(languages: Language[] = [Language.EN, Language.FR]): void {
        this.translate.addLangs(languages);
        this.translate.setDefaultLang(Language.EN);

        const browserLang = this.translate.getBrowserLang();
        const defaultBrowserLang = /en|fr/.exec(browserLang) ? browserLang : Language.EN;
        const savedTranslationLanguage = getDataFromLocalStorage<string>(TRANSLATION_STORAGE_KEY, defaultBrowserLang);
        setDataFromLocalStorage(TRANSLATION_STORAGE_KEY, savedTranslationLanguage);
        this.translate.use(savedTranslationLanguage);
    }

    public changeLanguage(langCode: Language): void {
        setDataFromLocalStorage(TRANSLATION_STORAGE_KEY, langCode);
        this.translate.use(langCode).subscribe(() => {
            this.currentLanguage.next(langCode);
        });
    }
}
