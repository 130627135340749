import { Departments } from './../../shared/config/work-orders-config';
import { Component, Inject, OnInit } from '@angular/core';
import { Countries, Disciplines } from '@/app/shared/config/work-orders-config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@/app/shared/services/user.service';

export interface UserObject {
    userAffiliate: string;
    discipline: string;
    department: string;
    userName: string;
    userEmail: string;
    // dateFormat: string;
}
export interface UserData {
    name: string;
    userName: string;
    // dateFormat: string;
}
@Component({
    selector: 'app-user-def-dialog',
    templateUrl: './user-def-dialog.component.html',
    styleUrls: ['./user-def-dialog.component.scss'],
})
export class UserDefDialogComponent implements OnInit {
    countries: Array<string> = Countries;
    selectedCountry: string = this.countries[0];
    disciplines: Array<string> = Disciplines;
    discipline: string;
    departments: Array<string> = Departments;
    department: string;
    loggedInUserEmail: string;
    loggedInUserName: string;
    userDefObj: UserObject;
    constructor(
        private dialogRef: MatDialogRef<UserDefDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,

        private userService: UserService
    ) {}

    ngOnInit(): void {
        if (this.userService.user) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.loggedInUserEmail = this.userService.user.username as string;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.loggedInUserName = this.userService.user.name as string;
        } else {
            this.loggedInUserEmail = '';
            this.loggedInUserName = '';
        }
    }
    setCountry(country: string): void {
        this.selectedCountry = country;
    }

    setDiscipline(discipline: string): void {
        this.discipline = discipline;
    }

    setDepartment(department: string): void {
        this.department = department;
    }

    isFormValid(): boolean {
        return this.disciplineAndDepartmentIsPresent();
    }

    disciplineAndDepartmentIsPresent(): boolean {
        return !!this.discipline && !!this.department;
    }

    save(userDetails: UserData): void {
        this.userDefObj = {
            userAffiliate: this.selectedCountry,
            discipline: this.discipline,
            department: this.department,
            userName: userDetails.name ? userDetails.name : this.loggedInUserName,
            userEmail: userDetails.userName ? userDetails.userName : this.loggedInUserEmail,
            // dateFormat: this.selectedDateFormat
        };
        this.dialogRef.close(this.userDefObj);
    }
}
