import { Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import { fadeInAnimation } from '@utils';
import { SidebarService } from '@app/shared/services/sidebar.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    animations: [fadeInAnimation],
})
export class LayoutComponent {
    constructor(public sidebarService: SidebarService) {}

    public getRouterOutletState(outlet: RouterOutlet): ActivatedRoute | string {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }
}
