import { Component, OnInit } from '@angular/core';
import { LongDescriptionService } from '@services';
import { LongDescription } from '@app/models/long-description';

@Component({
    selector: 'app-panel-material-information',
    templateUrl: './panel-material-information.component.html',
    styleUrls: ['./panel-material-information.component.scss'],
})
export class PanelMaterialInformationComponent implements OnInit {
    materialId: number;
    dataLongDescription: LongDescription | null;
    viewPanel: boolean;
    matnr: string;

    constructor(private longDescriptionService: LongDescriptionService) {}

    ngOnInit(): void {
        this.getDataViewPanel();
        this.getDataLongDescription();
        this.getMatnr();
    }

    closePanel(): void {
        this.longDescriptionService.setViewPanel(false);
    }
    getDataViewPanel(): void {
        this.longDescriptionService.sharedViewPanel.subscribe((result) => {
            this.viewPanel = result;
        });
    }

    private getDataLongDescription(): void {
        this.longDescriptionService.sharedDataLongDescription.subscribe((result) => {
            this.dataLongDescription = result;
        });
    }

    private getMatnr(): void {
        this.longDescriptionService.sharedMatnr.subscribe((result) => {
            this.matnr = result;
        });
    }
}
