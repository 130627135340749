import { Component } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    loaders = this.loader.value$.pipe(
        delay(1000),
        withLatestFrom(this.loader.value$),
        map((v) => v[1])
    );

    constructor(private loader: LoadingBarService) {}
}
